import React from "react";
import { graphql, PageProps } from "gatsby";
import { Box, Heading } from "grommet";

import Section from "@components/Section";
import HubSpotSectionContent from "@components/HubSpotSectionContent";
import Seo from "@components/Seo";

const ContactPage = ({
  data: { site },
  location,
}: PageProps<Queries.ContactQuery>) => {
  return (
    <Box>
      <Seo title="Contact" ogUrl={location?.href} />
      <Section constrained="desktop" background="brand">
        <HubSpotSectionContent
          pad={{ vertical: "large" }}
          maxWidth="540px"
          hsPortalId={site?.hsPortalId}
          hsFormId={site?.hsFormId}
        />
      </Section>
    </Box>
  );
};

export default ContactPage;

export const query = graphql`
  query Contact {
    site: sanitySite {
      ...HubSpotForm
    }
  }
`;
